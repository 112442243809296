<template>
  <div v-if="isEligibleForOnboarding">
    <b-card class="main-onboard-card">
      <div class="responsive-container">
        <div class="content-left onboard-left">
          <div class="sidebar">
            <div class="mt-4 text-center">
              <!-- Center the content -->
              <div class="progress-circle">
                <!-- Apply text-success class to the progress percentage -->
                <p class="mt-1 text-success" style="font-size: 18px;">
                  {{ progress }}%
                </p>
              </div>
              <ul class="steps ml-1 text-center">
                <!-- Center the steps list -->
                <li v-for="(step, index) in steps" :key="index"
                  :class="{ completed: index < currentStep || progress == 100 }" class="step">
                  <span class="check-mark" v-if="index < currentStep">
                    <b-icon class="check-mark" icon="check-circle-fill" color="orange" font-scale="1" />
                  </span>
                  <span class="check-mark" v-else-if="progress == 100">
                    <b-icon icon="check-circle-fill" color="#2cc474" font-scale="1" />
                  </span>
                  <span class="check-mark" v-else>
                    <b-icon icon="check-circle-fill" :color="index === currentStep ? '#F29F58' : 'grey'"
                      font-scale="1" />
                  </span>
                  <span style="font-weight: bold;">
                    {{ step }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="content-right">
          <p style="float: right; font-weight:bold; font-size:11px">
            <feather-icon icon="LockIcon" class="cursor-pointer" />Your data is safe and protected.
          </p>          
          <div class="form-section" v-if="(!this.create_client && this.step1) || this.stepper.step1">
            <div class="mt-1">
              <h2 class="welcome-text">Welcome to D2Scale!</h2>
              <p class="instruction-text">
                Provide your company and account details accurately, including
                your industry, to unlock tailored marketing insights. In just a
                few steps, D2Scale helps you analyze campaign data, benchmark
                performance, and optimize strategies with AI-powered
                recommendations. Let's get started!
              </p>
              <div class="mt-2 alignment_item">
                <b-row>
                  <b-col cols="12">
                    <b-form-group>
                      <label for="client-name">Account Name <span class="validation">*</span></label>
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather-icon icon="UserIcon" class="cursor-pointer" />
                        </b-input-group-prepend>
                        <b-form-input class="create-account-form" v-model="client.name" id="client-name"
                          placeholder="Enter client name" />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <label for="client-sector">Sector <span class="validation">*</span></label>
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather-icon icon="PieChartIcon" class="cursor-pointer" />
                        </b-input-group-prepend>
                        <b-form-select class="create-account-form" placeholder="Select client sector"
                          v-model="client.sector" :options="clientSectors" />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col cols="12">
                    <div>
                      <label for="business-type">Type of Business <span class="validation">*</span></label>
                      <v-select class="select-account-create" label="name" placeholder="Select Business(s) type"
                        v-model="client.selectedBusinessType" multiple :options="businessType"
                        :deselectFromDropdown="true" />
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <b-button v-if="!this.create_client" @click="addClient" :disabled="!this.client.name ||
                      !this.client.sector ||
                      !this.client.selectedBusinessType
                      " class="mt-2 add-button" block variant="primary">
                      Add an Account
                    </b-button>
                    <b-button v-else @click="updateClient" :disabled="!this.client.name ||
                      !this.client.sector ||
                      !this.client.selectedBusinessType
                      " class="mt-2 add-button" block variant="primary">
                      Update Account
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div style="display: flex; justify-content:space-between">
              <div></div>
              <div> <b-button @click="goToStep2" variant="primtary" v-if="channel_accounts_selected">
                  Next
                </b-button></div>

            </div>
          </div>

          <!-- Step 2: Integration Section -->
          <div class="channel-section"
            v-else-if="(create_client && !channel_accounts_selected && this.step2) || this.stepper.step2">
            <div class="mt-4">
              <h2 class="welcome-text">Central Dashboard for all your Digital Campaigns
              </h2>
              <p class="instruction-text">
                Link your Google Ads, Meta Ads account and get a single view of what campaigns are working best.
              </p>
              <div>
                <Channels :slug="slug" :next="nextAction" :role="role"
                  @selectFacebookAdsAccount="fetchFacebookCustomers" :integrations="this.integrations"
                  @selectGoogleAdsCustomers="fetchGoogleAdsCustomers" :isFetchingAdsCustomers="isFetchingAdsCustomers"
                  @googleAdsConnect="googleAdsConnect" :googleAdsCustomerID="googleAdsCustomerID" :googleAds="googleAds"
                  :facebookAccountName="facebookAccountNames" @fetchFacebookAdAccounts="fetchFacebookAdAccounts"
                  @facebookConnect="facebookConnect" :isFetchingAdsFacebook="isFetchingAdsFacebook"
                  :isFetchingFacebookAccounts="isFetchingFacebookAccounts" />
              </div>
            </div>
            <div style="display: flex; justify-content:space-between">
              <b-button variant="primtary" @click="goToStep1">
                Back
                <!-- <feather-icon icon="ChevronLefttIcon" size="25" /> -->
              </b-button>
              <b-button variant="primtary" @click="goToStep3" v-if="channel_accounts_selected">
                Next
                <!-- <feather-icon icon="ChevronRightIcon" size="25" /> -->
              </b-button>
            </div>
          </div>
          <AdsCustomerSelectorModalV2 :adsCustomers="googleAdsCustomers"
            :adsCustomerSelectorModal="adsCustomerSelectorModal"
            @toggleAdsCustomerSelectorModal="toggleAdsCustomerSelectorModal" @saveAdsCustomerID="saveAdsCustomerID"
            :googleSelected="googleSelected" :googleSelectedIds="googleSelectedIds" />
          <MultiChannelSelectorModal :isFetchingFacebookAccountData="isFetchingFacebookAccountDatas"
            :facebookCustomersTokens="facebookCustomersAllTokens" :facebookAdsModal="facebookAdsModal"
            :facebookCustomers="facebookCustomers" @update-facebook-modal="updateFacebookModal"
            @toggleFacebookModal="toggleFacebookModal" @token-prev-value="getPrevTokenValue"
            @token-next-value="getNextTokenValue" @saveFacebookccountID="saveFacebookccountID"
            :facebookSelected="facebookSelected" :modalType="modalTypeCheck" />
          <!-- Step 3: Final Setup Section -->
          <div class="form-section" v-if="
            (this.create_client &&
              channel_integration &&
              channel_accounts_selected &&
              !data_synced && this.step3) || this.stepper.step3
          ">
            <div class="mt-4">
              <h2>Sync Your Data & Start Analyzing</h2>
              <p>
                You're almost done! Just select the time period for which you'd
                like to analyze historical data. Once synced, you'll get insights
                and trends to optimize your campaigns.
              </p>
              <b-row class="mt-1 p-3">
                <b-col :cols="dynamicCols" v-if="this.google_ads_selected">
                  <div>
                    <label for="business-type">Select Duration for Google-Ads data</label>
                    <v-select :clearable="false" label="name" v-model="googleSync.selectedSyncType"
                      :options="syncType" />
                  </div>
                </b-col>
                <b-col :cols="dynamicCols" v-if="this.facebook_ads_connected">
                  <div>
                    <label for="business-type">Select Duration for Meta-Ads data</label>
                    <v-select :clearable="false" label="name" v-model="metaSync.selectedSyncType"
                      :options="syncMetaType" />
                  </div>
                </b-col>
                <b-col cols="12">
                  <b-button @click="syncData" :disabled="!this.channel_accounts_selected ||
                    (this.googleSync.selectedSyncType.value === '' &&
                      this.metaSync.selectedSyncType.value === '')
                    " class="mt-2" block variant="primary">
                    Sync Your Data
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div style="display: flex; justify-content:space-between">
              <b-button variant="primtary" @click="goToStep2">
                Back
              </b-button>

            </div>
            <!-- <div style="display: flex; justify-content:space-between">
            <b-button variant="primtary">Back</b-button>
            <b-button variant="primtary" v-if="channel_accounts_selected">Next</b-button>
          </div> -->
            <!-- <b-button @click="finishSetup" variant="success" class="mt-2">Finish</b-button> -->
          </div>
          <div class="form-section" v-if="
            (this.create_client &&
              channel_integration &&
              channel_accounts_selected &&
              data_synced && this.step4) ||
            this.stepper.step4
          ">
            <template>
              <div class="wait-container" v-if="this.completeProcess !== 100">
                <div class="wait-progress-circle">
                  <div class="wait-inner-circle"></div>
                </div>

                <!-- Linear Progress Bar -->
                <div style="display: flex;">
                  <div class="wait-progress-bar" style="width: 85%;">
                    <div class="wait-progress-fill" :style="{ width: completeProcess + '%' }"></div>
                  </div>
                  <div style="width: 15%; font-weight:bold; font-size:11px; margin-top:17px">{{
                    parseFloat(this.completeProcess.toFixed(1)) }} %</div>
                </div>
                <!-- Text Content -->
                <div class="wait-text-container">
                  <h3>We're currently fetching your ads data</h3>
                  <p>This process may take a few minutes. Please be patient while we retrieve and process your ads data.
                  </p>

                </div>

                <p style="text-align: left !important; font-weight:bold; font-size:13px; margin-top:20px">FAQs</p>
                <div class="faq-container-wait mt-1">
                  <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
                    <div class="faq-question" @click="toggleAnswer(index)">
                      <h4 style="font-size: 14px;">{{ faq.question }}</h4>
                      <span v-if="faq.showAnswer" class="icon">&#x25B2;</span>
                      <span v-else class="icon">&#x25BC;</span>
                    </div>
                    <transition name="fade">
                      <div v-if="faq.showAnswer" class="faq-answer">
                        <p>{{ faq.answer }}</p>
                      </div>
                    </transition>
                  </div>
                </div>

              </div>
              <div v-else>
              <div  class="success-page-container">
                <div class="password-changed-icon">
                  <img src="@/assets/images/logo/Successmark.png" alt="login-page-logo" />
                </div>
                <h1 class="password-changed-title">
                  Onboarding Completed Successfully!
                </h1>
                <p class="password-changed-message">
                  Data has been successfully retrieved. You may now proceed with using the app.
               </p>  
                <button class="password-changed-button" @click="navigateToDashboard">
                  Start Analyze Your Data
                </button>
              </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BCardBody,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BTabs,
  BTab,
  BPagination,
  BInputGroupPrepend,
  BInputGroupText,
  BFormSelect,
  BFormGroup,
  BIcon,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import { getUserToken } from "@/auth/utils";
import Cookies from "js-cookie";
import Channels from "./Channels.vue";
import MultiChannelSelectorModal from "../Client/MultiChannelSelectorModal.vue";
import AdsCustomerSelectorModalV2 from "../Client/AdsCustomerSelectorV2.vue";
export default {
  data() {
    return {
      faqs: [
        {
          question: "How is my data being stored?",
          answer: "Your data is securely fetched and stored in our system following industry-standard encryption protocols to ensure its safety.",
          showAnswer: false
        },
        {
          question: "Is my data secure?",
          answer: "Yes, we take security very seriously. All data is encrypted during transfer and stored in secure, protected databases to prevent unauthorized access.",
          showAnswer: false
        },
        {
          question: "When can I access my data?",
          answer: "You can access your data at any time after it has been successfully fetched and stored in our system. Please note that it may take up to 30 minutes for the data to be processed and available.",
          showAnswer: false
        },
        {
          question: "Is there a trial period for the platform?",
          answer: "Yes, we offer a completely free trial for the first three months. During this period, you can access all the features without any charges.",
          showAnswer: false
        },
        {
          question: "Will I be charged after the trial period?",
          answer: "After the three-month free trial, we will charge a minimal amount for continued access to the platform. The pricing is designed to be affordable and provide value.",
          showAnswer: false
        },
        {
          question: "How can I cancel my subscription?",
          answer: "You can cancel your subscription at any time by reaching out to our support team or managing your subscription settings in the account dashboard.",
          showAnswer: false
        }
      ],
      completeProcess: 100,
      duration: 55000, 
      interval: null,
      clientData: null,
      firstTimeOnboard: false,
      create_client: false,
      stepper: {
        step1: false,
        step2: false,
        step3: false,
        step4: false
      },
      step1: true,
      step2: true,
      step3: true,
      step4: true,
      facebook_ads_connected: false,
      channel_integration: false,
      google_ads_selected: false,
      channel_accounts_selected: false,
      data_synced: false,
      nextAction: "",
      isFetchingFacebookAccounts: false,
      maxLength: 2,
      facebookAdsModal: false,
      facebookCustomers: [],
      currentStep: 1,
      modalTypeCheck: "",
      facebookAdsAccountSelectorModal: false,
      facebookCustomersAllTokens: null,
      isFetchingFacebookAccountDatas: false,
      isGoogleAdsDataSyncing: false,
      isMetaAdsDataSyncing: false,
      isFetchingAdsFacebook: false,
      facebookAccountsData: [],
      facebookAccountNames: [],
      facebookSelected: [],
      googleAds: {},
      googleAdsCustomerID: null,
      isNewUser: null,
      isExternalUser: null,
      adminId: null,
      integrations: {},
      pagination: {
        limit: 7,
        currentPage: 1,
      },
      isClientLoadingProperly: false,
      clients: [],
      client: {
        status: true,
        integrations: {
          google: null,
          facebook: null,
          googleAds: null,
        },
        analytics: {
          google: {
            users: {},
            sessions: {},
            bounceRate: {},
            avgSessionDuration: {},
          },
        },
        googleViewID: null,
        googleViewProfile: null,
        googleAdsCustomerID: null,
        googleAds: {
          googleAdsCustomerID: null,
          manager: false,
          customerClientID: null,
          managerName: null,
          customerName: null,
          customerNames: null,
        },
        role: "VIEWER",
      },
      googleSelectedIds: [],
      googleSelected: [],
      adsCustomerSelectorModal: false,
      isFetchingAdsCustomers: false,
      googleAdsError: false,
      googleAdsCustomers: [],
      clientID: "",
      slug: "",
      role: "",
      steps: [
        "Enter Account Details",
        "Integrate Digital Ads Platforms",
        "Begin Analysis",
      ],
      client: {
        clientID: "",
        name: "",
        sector: null,
        selectedBusinessType: [],
      },
      clientUpdate: {
        clientID: "",
        name: "",
        sector: null,
        selectedBusinessType: [],
      },
      clientSectors: [
        { value: null, text: "Select Sector", disabled: true },
        "E-Commerce",
        "Healthcare",
        "Education",
        "Real Estate",
        "Retail",
        "App",
        "Custom",
      ],
      selectedBusinessType: [],
      businessType: ["Ecommerce", "Leadgen", "Branding", "Apps"],
      syncType: [
        { name: "Please Select Duration", value: "" },
        { name: "Last 90 Days", value: "Last_90_DAYS" },
        { name: "Last 60 Days", value: "Last_60_DAYS" },
        { name: "Last 30 Days", value: "Last_30_DAYS" },
        { name: "Last 7 Days", value: "Last_7_DAYS" },
      ],
      syncMetaType: [
        { name: "Please Select Duration", value: "" },
        { name: "Last 90 Days", value: "Last_90_DAYS" },
        { name: "Last 60 Days", value: "Last_60_DAYS" },
        { name: "Last 30 Days", value: "Last_30_DAYS" },
        { name: "Last 7 Days", value: "Last_7_DAYS" },
      ],
      googleSync: {
        selectedSyncType: { name: "Please Select Duration", value: "" },
      },
      metaSync: {
        selectedSyncType: { name: "Please Select Duration", value: "" },
      },
      industries: [
        "E-commerce",
        "Education",
        "Healthcare",
        "Finance",
        "Technology",
      ],
      timezones: ["UTC", "IST", "PST", "EST", "CST"],
      form: {
        companyName: "",
        accountAlias: "",
        industry: "",
        timezone: "",
        language: "English",
      },
    };
  },
  computed: {
    dynamicCols() {
      return this.facebook_ads_connected && !this.google_ads_selected || !this.facebook_ads_connected && this.google_ads_selected ? 12 : 6
    },
    isEligibleForOnboarding() {
      return true;
      // return (
      //   (this.isNewUser === "true" && this.isExternalUser === "true") ||
      //   (this.clients.length === 0 &&
      //     this.isNewUser === "false" &&
      //     this.isExternalUser === "true")
      // );
    },
    progress() {
      let progressPercentage = 0;
      if (!this.create_client) {
        progressPercentage = 0;
      } else if (this.create_client && !this.channel_integration) {
        progressPercentage = 33.3;
      } else if (this.channel_integration && !this.data_synced) {
        progressPercentage = 66.6;
      } else if (this.data_synced) {
        progressPercentage = 100;
      }

      // Dynamically set currentStep based on the progress
      if (progressPercentage >= 66) {
        this.currentStep = 2; // Set to step 2 if progress is 66% or higher
      } else if (progressPercentage >= 33) {
        this.currentStep = 1; // Set to step 1 if progress is 33% or higher
      } else if (progressPercentage === 100) {
        this.currentStep = 3;
      } else {
        this.currentStep = 0; // Default to step 0
      }

      return progressPercentage.toFixed(1); // Keeps one decimal place
    },
    isBtnDisabledForAddClient() {
      return (
        this.client.selectedBusinessType.length === 0 ||
        this.client.name === "" ||
        this.client.sector === null
      );
    },
  },
  methods: {
    toggleAnswer(index) {
      this.faqs[index].showAnswer = !this.faqs[index].showAnswer;
    },
    startProgress() {
    let selectedDuration = this.googleSync.selectedSyncType.value || this.metaSync.selectedSyncType.value;
    // Define duration mapping in minutes (converted to milliseconds)
    const durationMap = {
      "Last_7_DAYS": 5 * 60 * 1000, // 5 minutes
      "Last_14_DAYS": 10 * 60 * 1000, // 10 minutes
      "Last_30_DAYS": 15 * 60 * 1000, // 15 minutes
      "Last_60_DAYS": 30 * 60 * 1000, // 30 minutes
      "Last_90_DAYS": 45 * 60 * 1000, // 45 minutes (adjust this based on your requirement)
    };

    this.completeProcess = 0; // Reset completeProcess
    this.duration = durationMap[selectedDuration]; // Set duration from the map
    let startTime = Date.now();
    clearInterval(this.interval); // Clear previous interval if any

    this.interval = setInterval(() => {
      let elapsedTime = Date.now() - startTime;
      this.completeProcess = Math.abs(Math.min((elapsedTime / this.duration) * 100, 100));

      if (this.completeProcess >= 100) {
        clearInterval(this.interval);
      }
    }, 100); // Update every 100ms
   },
    goToStep1() {
      this.stepper.step1 = true;
      this.stepper.step2 = false;
      this.stepper.step3 = false;
      this.stepper.step4 = false;

      this.step1 = true;
      this.step2 = false;
      this.step3 = false;
      this.step4 = false;

      this.prefilClientData()
    },

    goToStep2() {
      this.stepper.step1 = false;
      this.stepper.step2 = true;
      this.stepper.step3 = false;
      this.stepper.step4 = false;

      this.step1 = false;
      this.step2 = true;
      this.step3 = false;
      this.step4 = false;
    },

    goToStep3() {
      this.stepper.step1 = false;
      this.stepper.step2 = false;
      this.stepper.step3 = true;
      this.stepper.step4 = false;

      this.step1 = false;
      this.step2 = false;
      this.step3 = true;
      this.step4 = false;
    },
    goToStep4() {
      this.stepper.step1 = false;
      this.stepper.step2 = false;
      this.stepper.step3 = false;
      this.stepper.step4 = true;
      this.makeStep4True()

    },
    makeStep4True() {
      this.step1 = false;
      this.step2 = false;
      this.step3 = false;
      this.step4 = true;
    },
    prefilClientData() {
      this.client.name = this.clients.name
      this.client.sector = this.clients.sector
      this.client.selectedBusinessType = this.clients.businessType[0]
    },
    navigateToDashboard() {
      this.$router.push(`/client/${this.slug}/dashboard/meta-ads`);
    },
    getNextTokenValue(value) {
      this.fetchFacebookCustomers(value, true, false);
    },
    getPrevTokenValue(value) {
      this.fetchFacebookCustomers(value, false, true);
    },
    updateFacebookModal(value) {
      this.facebookAdsModal = value;
    },
    fetchFacebookCustomers(value, next, prev) {
      this.modalTypeCheck = "facebook";
      this.isFetchingFacebookAccountDatas = true;
      this.isFetchingFacebookAccounts = true;
      this.facebookCustomers = [];
      this.facebookCustomersAllTokens = null;

      let url = `${process.env.VUE_APP_SERVER_URL}/api/facebook/ad-accounts?clientID=${this.clientID}&limit=100&getAllData=true`;

      if (value == true) {
        this.paginationToken = "";
      } else {
        this.paginationToken = value;
        if (next) {
          url += `&nextPageToken=${this.paginationToken}`;
        } else if (prev) {
          url += `&prevPageToken=${this.paginationToken}`;
        }
      }

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.paginationToken = "";
          this.isFetchingFacebookAccountDatas = false;
          this.isFetchingFacebookAccounts = false;
          this.facebookCustomers = response.data.accountsData.data;
          this.facebookCustomersAllTokens = response.data.accountsData.paging;
          this.toggleFacebookModal(true);
        })
        .catch((error) => {
          this.paginationToken = "";
          this.isFetchingFacebookAccounts = false;
          this.isFetchingFacebookAccountDatas = false;
          console.log(error);
        });
    },
    toggleFacebookModal(state) {
      if (state) {
        this.facebookSelected = [];
        this.facebookCustomers.filter((account) => {
          if (this.facebookAccountNames.includes(account.name)) {
            this.facebookSelected.push(account);
          }
        });
      }
      this.facebookAdsModal = state;
    },
    syncData() {
      this.progressPercentage = 0;
      this.startProgress();
      if (
        this.googleSync.selectedSyncType &&
        this.googleSync.selectedSyncType.value.length > 0
      ) {
        this.syncGoogleAdsData();

      }
      if (
        this.metaSync.selectedSyncType &&
        this.metaSync.selectedSyncType.value.length > 0
      ) {
        this.syncMetaAdsData();
      }

      this.goToStep4()

    },
    syncGoogleAdsData() {
      this.isGoogleAdsDataSyncing = true;
      this.firstTimeOnboard = false
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/sync-data`,
          {
            clientID: this.clientID,
            dateRange: this.googleSync.selectedSyncType.value,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isGoogleAdsDataSyncing = false;
          this.showToast(
            "Success!",
            "Google Ads Sync Started!",
            "success"
          );
          this.fetchClients(this.pagination);
          this.isGoogleAdsDataSyncing = false;
          this.firstTimeOnboard = true
        })
        .catch((error) => {
          this.isGoogleAdsDataSyncing = false;
          this.firstTimeOnboard = false
          this.showToast("Error", "Unknown error occured!", "danger");
          console.log(error.response.data);
        });
    },
    syncMetaAdsData() {
      this.isMetaAdsDataSyncing = true;
      this.firstTimeOnboard = false
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/facebook/sync-data`,
          {
            clientID: this.clientID,
            dateRange: this.metaSync.selectedSyncType.value,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isMetaAdsDataSyncing = false;
          this.showToast(
            "Success!",
            "Meta Ads Sync Started!",
            "success"
          );
          this.fetchClients(this.pagination);
          this.isMetaAdsDataSyncing = false;
          this.firstTimeOnboard = true
        })
        .catch((error) => {
          this.isGoogleAdsDataSyncing = false;
          this.showToast("Error", "Unknown error occured!", "danger");
          console.log(error.response.data);
          this.firstTimeOnboard = false
        });
    },
    facebookConnect() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/facebook/login`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          const url = new URL(response.data);
          const search_params = url.searchParams;
          search_params.set("state", this.clientID);
          url.search = search_params.toString();
          const new_url = url.toString();
          window.location.replace(new_url);
          this.fetchClients(this.pagination);;
        })
        .catch((error) => {
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    fetchFacebookAdAccounts() {
      this.isFetchingAdsFacebook = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/facebook/get-ad-accounts`,
          {
            clientID: this.clientID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isFetchingAdsFacebook = false;
          const accountsData = response.data.accountsData;
          this.facebookAccountsData = accountsData.data;
          this.toggleFacebookAdsAccountSelectorModal(true);
        })
        .catch((error) => {
          this.facebookAccountsData = [];
          this.toggleFacebookAdsAccountSelectorModal(false);
          this.isFetchingAdsFacebook = false;
          this.showToast(error.response.data.error);
          this.modalTypeCheck = "";
        });
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    toggleFacebookAdsAccountSelectorModal(state) {
      this.facebookAdsAccountSelectorModal = state;
    },
    saveFacebookccountID(FacebookAccountData) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/facebook/ad-accounts/select`,
          {
            clientID: this.clientID,
            ...FacebookAccountData,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.toggleFacebookModal(false);
          this.showToast(
            "Meta Account Selected successfully",
            "Meta Ads Account(s) Integrated successfully",
            "success"
          );
          this.fetchClients(this.pagination);
        })
        .catch((error) => {
          console.log(error.response);
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    fetchClients(search) {
      this.isClientLoadingProperly = true;
      const storedDate = Cookies.get("lastVisitedDashboardDate");
      if (storedDate === this.todayDateCompare) {
        console.log("");
      } else {
        Cookies.set("lastVisitedDashboardDate", this.todayDateCompare, {
          expires: 1,
        });
      }
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/api/client/get`, search, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          const adminID = this.adminId;
          const matchingClient = response.data.clients.find(
            (client) => client.created_by === adminID
          );
          if (matchingClient) {
            this.clientUpdate.name = this.clients.name
            this.clients = matchingClient;
            this.clientID = this.clients._id;
            this.slug = this.clients.slug;
            this.role = this.clients.role;
            this.googleSelected = matchingClient.googleAds.customerNames;
            this.googleSelectedIds = matchingClient.googleAds.customerClientIDs;
            this.integrations = matchingClient.integrations;
            this.googleAdsCustomerID = matchingClient.googleAdsCustomerID;
            this.googleAds = matchingClient.googleAds;
            this.facebookAccountNames = matchingClient.facebook.adAccountNames;
            this.facebookSelected = this.facebookAccountNames;
            this.create_client = matchingClient.stepper.create_client;
            this.google_ads_connected =
              matchingClient.stepper.google_ads_connected;
            this.facebook_ads_connected =
              matchingClient.stepper.facebook_ads_connected;
            this.channel_integration =
              matchingClient.stepper.channel_integration;
            this.google_ads_selected =
              matchingClient.stepper.google_ads_selected;
            this.facebook_ads_selected =
              matchingClient.stepper.facebook_ads_selected;
            this.channel_accounts_selected =
              matchingClient.stepper.channel_accounts_selected;
            this.data_synced = matchingClient.stepper.data_synced;
          } else {
            console.warn("No matching client found for the provided adminID.");
          }
          this.isClientLoadingProperly = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.isClientLoadingProperly = false;
        });
    },
    toggleAdsCustomerSelectorModal(state) {
      this.adsCustomerSelectorModal = state;
    },
    googleAdsConnect() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/integrations/integrate/google-ads/init`,
          {
            clientID: this.clientID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          window.location.replace(response.data);
          this.fetchClients(this.pagination);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data && error.response.data.error) {
            // this.showToast("Error", error.response.data.error, "danger");
            return;
          }

          if (error.response.data && error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              const error = error.response.data.errors[i];
              // this.showToast("Error", `${error.msg - error.param}`, "danger");
            }
            return;
          }
          // this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    saveAdsCustomerID(customerData) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/integrations/integrate/google-ads/save-customer-id`,
          {
            ...customerData,
            clientID: this.clientID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Customer ID updated",
            "Google Ads Account(s) Integrated successfully",
            "success"
          );
          this.toggleAdsCustomerSelectorModal(false);
          this.fetchClients(this.pagination);
        })
        .catch((error) => {
          // this.toggleAccountSelectorModal(false);
          if (error.response.data && error.response.data.error) {
            this.showToast("Error", error.response.data.error, "danger");
            return;
          }

          if (error.response.data && error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              const error = error.response.data.errors[i];
              this.showToast("Error", `${error.msg - error.param}`, "danger");
            }
            return;
          }

          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    fetchGoogleAdsCustomers() {
      this.isFetchingAdsCustomers = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/get-customers`,
          {
            clientID: this.clientID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isFetchingAdsCustomers = false;
          for (let i = 0; i < response.data.customers.length; i++) {
            const customer = response.data.customers[i];
            if (!customer.manager) {
              response.data.customers[i].loading = false;
            } else {
              if (customer.childAccounts) {
                for (let j = 0; j < customer.childAccounts.length; j++) {
                  response.data.customers[i].childAccounts[j].loading = false;
                }
              }
            }
          }
          this.googleAdsCustomers = response.data.customers;
          this.toggleAdsCustomerSelectorModal(true);
        })
        .catch((error) => {
          this.googleAdsError = true;
          // this.showToast("Error", error.response.data.error, "danger");
          this.isFetchingAdsCustomers = false;
          console.log(error);
        });
    },
    nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
      }
    },
    finishSetup() {
      alert("Setup Complete! You are ready to use D2Scale.");
      this.currentStep = 0; // Reset steps
    },
    addClient(bvModalEvt) {
      bvModalEvt.preventDefault();
      const name = this.client.name;
      const sector = this.client.sector;
      const businessType = this.client.selectedBusinessType[0];
      // const hourly_budget = this.client.hourly_budget;
      const clientID = this.client.clientID;
      let url = `${process.env.VUE_APP_SERVER_URL}/api/client/add`;
      let toastTitle = `Created`;
      let toastMessage = `Client created successfully!`;

      if (clientID) {
        url = `${process.env.VUE_APP_SERVER_URL}/api/client/update`;
        toastTitle = `Updated`;
        toastMessage = `Client updated successfully!`;
      }
      axios
        .post(
          url,
          {
            sector,
            // hourly_budget,
            name,
            clientID,
            businessType,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          // this.showToast(toastTitle, toastMessage, "success");
          this.fetchClients(this.pagination);
          this.selectedBusinessType = [];
          this.selectedSector = [];
          this.name = "";
          this.nextStep();
        })
        .catch((error) => {
          console.log(error);
          // this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    updateClient(bvModalEvt) {
      bvModalEvt.preventDefault();
      const name = this.client.name;
      const sector = this.client.sector;
      const businessType = this.client.selectedBusinessType;
      // const hourly_budget = this.client.hourly_budget;
      const clientID = this.clientID;
      let url = `${process.env.VUE_APP_SERVER_URL}/api/client/update`;
      let toastTitle = `Updated`;
      let toastMessage = `Client updated successfully!`;

      if (clientID) {
        url = `${process.env.VUE_APP_SERVER_URL}/api/client/update`;
        toastTitle = `Updated`;
        toastMessage = `Client Updated successfully!`;
      }
      axios
        .post(
          url,
          {
            sector,
            // hourly_budget,
            name,
            clientID,
            businessType,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          // this.showToast(toastTitle, toastMessage, "success");
          this.fetchClients(this.pagination);
          this.selectedBusinessType = [];
          this.selectedSector = [];
          this.name = "";
          this.nextStep();
          this.goToStep2()
        })
        .catch((error) => {
          console.log(error);
          // this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    onSubmit() {
      alert("Form submitted successfully!");
    },
  },
  components: {
    Channels,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormInput,
    BButton,
    BTabs,
    BTab,
    BPagination,
    BInputGroupPrepend,
    BInputGroupText,
    vSelect,
    BFormGroup,
    BIcon,
    AdsCustomerSelectorModalV2,
    MultiChannelSelectorModal,
    ToastificationContent,
  },
  beforeDestroy() {
    clearInterval(this.interval); // Cleanup interval on component destroy
  },
  mounted() {
    // this.startProgress(); 
    history.pushState("", document.title, window.location.pathname);
    if (this.$route.query.next) {
      this.nextAction = this.$route.query.next;
    }
    this.isNewUser = Cookies.get(useJwt.jwtConfig.userNewUser);
    this.isExternalUser = Cookies.get(useJwt.jwtConfig.userExternalUser);
    this.adminId = Cookies.get(useJwt.jwtConfig.adminID);
    this.fetchClients(this.pagination);
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.dashboard-overlay .rounded-sm {
  inset: -20px !important;
  height: 80vh !important;
}

.wait-container {
  text-align: center;
  background: white;
  padding: 100px 30px;
  border-radius: 10px;

  /* Circular Progress Indicator */
  /* Circular Progress Indicator (Always Rotating) */
  .wait-progress-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: conic-gradient(#2563eb 75%, #d1d5db 25%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40%;
    position: relative;
    animation: spin 2s linear infinite;
    /* Rotation animation */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* Inner Circle (White Background) */
  .wait-inner-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: white;
    position: absolute;
  }

  /* Linear Progress Bar */
  .wait-progress-bar {
    width: 100%;
    height: 10px;
    background: #d1d5db;
    border-radius: 5px;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
  }


  .wait-progress-fill {
    height: 100%;
    background: #2563eb;
    border-radius: 5px;
    transition: width 0.1s linear;
  }

  /* Text */
  .wait-text-container {
    margin-top: 20px;
  }

  .wait-text-container h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }

  .wait-text-container h3::before {
    content: "🕒";
    margin-right: 8px;
    font-size: 20px;
  }

  .wait-text-container p {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
  }
}

.faq-container-wait {
  max-width: 700px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;

  .faq-item {
    border-radius: 8px;
    background: #ffffff;
    margin-bottom: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }

  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    font-size: 12px !important;
    color: #333;
    font-weight: 500;
    background-color: #ffffff;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }


  .faq-answer {
    padding: 15px;
    font-size: 14px;
    text-align: left;
    color: #666;
    background-color: #fafafa;
    border-radius: 0 0 8px 8px;
  }

  .icon {
    font-size: 12px;
    color: #888;
    transition: transform 0.3s ease;
  }

  .faq-question:hover .icon {
    color: #333;
  }

  .faq-answer p {
    margin: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}


.alignment_item {
  padding: 0px 100px;
}

.addclientButton {
  background-color: #6c4e96 !important;
  border-radius: 12px !important;
  border: none !important;
}

.search-card {
  border-radius: 15px;
}

.search-form {
  background-color: #e4dfeb !important;
  border: none;
  margin-right: -10px !important;
  margin-left: -10px !important;
}

.searc-button {
  background-color: black !important;
  color: white !important;
  font-weight: bold;
  border-radius: 12px;
}

.search-icon {
  background-color: #e4dfeb !important;
  border-radius: 12px 0 0 12px !important;
  border: none;
  cursor: none;
}

@media only screen and (max-width: 767px) {
  .responsiveMode {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
  }

  .client-card {
    width: 350px !important;
  }

  .search-card {
    width: 400px;
  }

  .input-group {
    width: 220px !important;
  }

  .input-button {
    width: 150px !important;
    height: 40px !important;
    font-size: 10px !important;
    padding: 2px !important;
  }

  .responsiveness {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
  }

  .paginated-card {
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-bottom: 10px;
  }

  .add-new-client-card {
    width: 350px !important;
  }
}

.onboard {
  display: flex;
  margin-top: 30px;

  .container {
    display: flex;
    width: 100%;
    font-family: Arial, sans-serif;
  }

  /* Sidebar */
  .sidebar {
    width: 35%;
    height: 400px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    margin-top: 40px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    text-align: center;
  }

  .progress-circle {
    width: 80px;
    height: 80px;
    border: 5px solid #2cc474;
    border-radius: 50%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }

  .steps {
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }

  .step {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 12px;
    color: #555;
  }

  .check-mark {
    margin-right: 10px;
    color: #aaa;
  }

  /* Form Section */
  .form-section {
    width: 75%;
    padding: 30px 50px;
    box-sizing: border-box;
  }

  .channel-section {
    width: 75%;
    padding: 20px 10px;
    box-sizing: border-box;
  }

  h2 {
    margin: 0 0 10px;
    color: #333;
  }

  .instruction-text {
    color: #777;
    margin-bottom: 30px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: bold;
  }

  label span {
    color: red;
  }

  .submit-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .submit-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.steps .step {
  display: flex;
  align-items: center;
  margin: 10px 0;
  color: #333;
  /* Default color for incomplete steps */
}

.steps .step.completed {
  color: #2cc474;
  /* Green color for completed steps */
  font-weight: bold;
  /* Optional: make completed steps bold */
}

.steps .step .check-mark {
  margin-right: 5px;
  color: #2cc474;
  /* Ensure the check mark is also green */
}

.success-page-container {
  text-align: center;
  background: white;
  padding: 80px 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.password-changed-icon {
  width: 80px;
  height: 80px;
  background-color: #34c759;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}

.icon-check {
  width: 40px;
  height: 40px;
  fill: white;
}

.password-changed-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.password-changed-message {
  font-size: 16px;
  color: #777;
  margin-bottom: 30px;
}

.password-changed-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #1c1c1e;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.password-changed-button:hover {
  background-color: #333;
}

/* Container Styling */
.responsive-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

/* Default Styling for Mobile (Small screens) */
.content-left,
.content-right {
  flex: 0 0 100%;
  /* Stack content vertically */
  padding: 15px;
  text-align: left;
  align-items: left;
  color: rgb(95, 92, 92);
}


.main-onboard-card {
  min-height: 75vh;
  max-height: auto
}

.onboard-left {
  .container {
    display: flex;
    width: 100%;
    font-family: Arial, sans-serif;
  }

  /* Sidebar */
  .sidebar {
    width: 90%;
    height: 400px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    margin-top: 40px;
    background: #f7f7f7;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    text-align: center;
  }

  .progress-circle {
    width: 80px;
    height: 80px;
    border: 5px solid #2cc474;
    border-radius: 50%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }

  .steps {
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }

  .step {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 12px;
    color: #555;
  }

  .check-mark {
    margin-right: 10px;
    color: #aaa;
  }

  /* Form Section */
  .form-section {
    width: 75%;
    padding: 30px 50px;
    box-sizing: border-box;
  }

  .channel-section {
    width: 75%;
    padding: 20px 10px;
    box-sizing: border-box;
  }

  h2 {
    margin: 0 0 10px;
    color: #333;
  }

  .instruction-text {
    color: #777;
    margin-bottom: 30px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: bold;
  }

  label span {
    color: red;
  }

  .submit-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .submit-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

/* Mobile Devices (<= 576px) */
@media (max-width: 576px) {
  .alignment_item {
    justify-content: center;
    align-items: center;
    left: auto;
    right: auto;
  }

  .select-account-create {
    .vs__dropdown-toggle {
      width: 220px;
      background: none;
      border: 1px solid #d8d6de;
      border-radius: 0.357rem;
    }
  }

  .add-button {
    width: 225px !important;
  }

  .alignment_item {
    padding: 0px 0px;
  }

  .instruction-text {
    width: 310px !important;
    text-align: justify;
    text-justify: inter-word;
    justify-content: center;
    align-items: center;
  }

  .welcome-text {
    width: 330px !important;
    font-size: 18px;
  }

  .steps {
    list-style: none;
    padding: 0;
    margin-top: 20px;
    text-align: justify;
    text-justify: inter-word;
    align-items: center;
    justify-content: center;
  }

  .step {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 12px;
    color: #555;
    text-align: justify;
    text-justify: inter-word;
    align-items: center;
    justify-content: center;
  }

  /* Sidebar */
  .sidebar {
    width: 100%;
    height: 400px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    margin-top: 40px;
    background: #f7f7f7;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    text-align: center;
  }
}

/* Tablet Styling (Medium screens) */
@media (min-width: 768px) and (max-width: 1024px) {
  .alignment_item {
    padding: 0px 0px;
  }

  .instruction-text {
    width: 400px;
    text-align: justify;
    text-justify: inter-word;
    justify-content: center;
    align-items: center;
  }

  .content-left {
    flex: 0 0 50%;
    /* Adjust for equal width */
  }

  .content-right {
    flex: 0 0 50%;
    /* Adjust for equal width */
  }

  .steps {
    list-style: none;
    padding: 0;
    margin-top: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .step {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 12px;
    color: #555;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

/* Desktop Styling (Large screens) */
@media (min-width: 1025px) {
  .alignment_item {
    padding: 0px 0px;
  }

  .content-left {
    flex: 0 0 40%;
    /* 40% width */
  }

  .content-right {
    flex: 0 0 60%;
    /* 60% width */
  }
}
</style>
