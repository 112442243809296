<template>
  <div>
    <div v-if="filter" id="filters-card">
        <D2ScaleAi />
    </div>
    <div v-if="!filter" class="support-button draggable-btn"
    :style="{ top: position.y + 'px', left: position.x + 'px' }"
    @mousedown="startDrag" @click="filter = !filter">
      <div class="bot-icon">
          <img src="https://cdn-icons-png.flaticon.com/512/4712/4712035.png" alt="Bot Icon">
      </div>
      <div class="text-container">
          <div class="bot-name">D2Scale-AI</div>
          <div class="status">Online</div>
      </div>
  </div>
  <div class="support-button draggable-btn"
  :style="{ top: cancelposition.y + 'px', left: cancelposition.x + 'px' }"
  @mousedown="startDrag" v-if="filter" @click="filter = !filter">
    <feather-icon  size="14" icon="XCircleIcon" />
  </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BFormGroup,
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BIcon,
  BFormSelect,
  BBadge,
  BAvatar
} from "bootstrap-vue";
import D2ScaleAi from "./D2Scale-ai.vue";
import vSelect from "vue-select";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import Cookies from "js-cookie";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    BButton,
    BCard,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    BIcon,
    BBadge,
    BFormSelect,
    BAvatar,
    D2ScaleAi
  },
  props: {
    clientID: {
      type: String,
    },
  },
  data() {
    return {
      position: {
        x: window.innerWidth - 200,
        y: window.innerHeight - 80 
      },
      cancelposition: {
        x: window.innerWidth - 70,
        y: window.innerHeight - 40 
      },
      dragging: false,
      offset: { x: 0, y: 0 },
      profileImage: "",
      colors: ['#ffffff', '#ffffff', '#ffffff'],
      items: ['Last Week ROAS', 'Last Month ROAS'],
      adminFirstName: "",
      selectedLanguage: "hi-IN",
      languages: [
        { code: "hi-IN", name: "HINDI" },
        { code: "bn-IN", name: "BENGALI" },
        { code: "kn-IN", name: "KANNADA" },
        { code: "ml-IN", name: "MALAYALAM" },
        { code: "mr-IN", name: "MARATHI" },
        { code: "od-IN", name: "ODIYA" },
        { code: "pa-IN", name: "PUNJABI" },
        { code: "ta-IN", name: "TAMIL" },
        { code: "te-IN", name: "TELUGU" },
        { code: "gu-IN", name: "GUJARATI" },
      ],
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      transcription: "",
      error: "",
      filter: false,
      text: "",
      translatedText: "",
      language: "es", // Default translation to Spanish
      isListening: false,
    };
  },
  created() {
    this.profileImage = Cookies.get(useJwt.jwtConfig.adminUrl);
    this.adminFirstName = Cookies.get(useJwt.jwtConfig.adminFirstName);
  },
  computed: {
    formattedLanguages() {
      let language = this.languages.map((lang) => ({
        value: lang.code,
        text: lang.name,
      }));
      return language;
    },
    selectedLanguageName() {
      const selected = this.languages.find(
        (lang) => lang.code === this.selectedLanguage
      );
      return selected ? selected.name : "";
    },
  },
  methods: {
    startDrag(event) {
      this.dragging = true;
      this.offset.x = event.clientX - this.position.x;
      this.offset.y = event.clientY - this.position.y;
      document.addEventListener("mousemove", this.drag);
      document.addEventListener("mouseup", this.stopDrag);
    },
    drag(event) {
      if (this.dragging) {
        this.position.x = event.clientX - this.offset.x;
        this.position.y = event.clientY - this.offset.y;
      }
    },
    stopDrag() {
      this.dragging = false;
      document.removeEventListener("mousemove", this.drag);
      document.removeEventListener("mouseup", this.stopDrag);
    },
    getRandomColor() {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      return this.colors[randomIndex];
    },
    async startRecording() {
      this.resetState();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);

      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.onstop = this.handleRecordingStop;

      this.mediaRecorder.start();
      this.isRecording = true;
    },
    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
    },
    async handleRecordingStop() {
      const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
      const form = new FormData();
      form.append("file", audioBlob);
      form.append("language_code", this.selectedLanguage);
      form.append("model", "saarika:v1");

      const options = {
        method: "POST",
        headers: {
          "api-subscription-key": "62b9a4e9-d62b-4987-a3ce-226c540c08e1", // Add your API key here
        },
        body: form,
      };

      try {
        const response = await fetch(
          "https://api.sarvam.ai/speech-to-text",
          options
        );
        const data = await response.json();
        this.transcription = data.transcript;
      } catch (err) {
        this.error = "Error fetching transcription.";
        console.error(err);
      }
    },
    resetState() {
      this.transcription = "";
      this.audioChunks = [];
      this.error = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";


#filters-card {
  position: fixed;
  height: 50vh !important;
  top: 85px;
  right: 40px;
  bottom: 70px;
  width: 100%;
  max-width: 800px;
  z-index: 0;
}

.draggable-btn {
  position: fixed; /* Ensure it stays in the same place even when scrolling */
  cursor: grab;
  user-select: none;
}

.draggable-btn:active {
  cursor: grabbing;
}
.support-button {
  display: flex;
  align-items: center;
  background: #f0f2f5;
  border-radius: 50px;
  border: 4px solid #200E6B;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  width: fit-content;

.bot-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #2D8CFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
}

.bot-icon::after {
  content: '';
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 10px;
  height: 10px;
  background: #28C940;
  border-radius: 50%;
  border: 2px solid white;
}

.bot-icon img {
  width: 24px;
  height: 24px;
}

.text-container {
  display: flex;
  flex-direction: column;
}

.bot-name {
  font-size: 14px;
  margin-top: 0px;
  font-weight: bold;
  color: #200E6B;
}

.status {
  margin-top: -5px;
  font-size: 12px;
  color: #9B9B9B;
}
}

#google-ads-filter-float {
  position: fixed;
  right: 30px;
  bottom: 40px;
  z-index: 9;

  .btn-primary {
    box-shadow: 0 0 10px #7367f0;
  }
}

.audio-recorder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  border: 2px solid #704c94;
  border-radius: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  color: #704c94;
  margin-bottom: 20px;
}

.language-select {
  width: 100%;
  max-width: 400px; /* Limit the width for better appearance */
  margin-bottom: 15px;
}

.button-container {
  margin-bottom: 15px;
}

.record-button {
  background-color: #704c94;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.record-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.record-button:hover:not(:disabled) {
  background-color: #704c94;
}

.status {
  font-size: 18px;
  color: #ff0000; /* Red for recording status */
  margin-top: 10px;
}

.transcription {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #e8f5e9; /* Light green background */
  width: 100%;
  max-width: 600px;
}

.error {
  color: #ff0000; /* Red for error messages */
  margin-top: 10px;
}
.chat-div{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 5px;
  width: 450px;
  padding: 10px;
  .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem 1.2rem;
    border-radius: 12px;
    font-size: 1rem;
    color: #333;
  }
}

.message-container {
  display: flex;
  align-items: flex-start;
  margin: 10px;
}

.message-container-main{
  display: flex;
  align-items: flex-start;
  margin: 10px;
}
.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.message-content {
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: bold;
  margin-bottom: 5px;
}

.chat-bubble {
  background-color: #f1f5f9;
  color: #333;
  padding: 10px 15px;
  border-radius: 10px;
  max-width: 100%;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 5px;
}
.time-stamp-user {
  font-size: 12px;
  color: gray;
  align-self: flex-start;
  margin-left: 8px;
}
.time-stamp {
  font-size: 12px;
  color: gray;
  align-self: flex-end;
}
.blink_text {
  color: black;
  animation-name: blinker;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.chat-wrapper {
  width: 350px;
  display: flex;
  justify-content: flex-end;
}

.chat-message {
  display: flex;
  align-items: flex-start;
  max-width: 60%;
  margin-bottom: 20px;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
  object-fit: cover;
}

.message-content-user {
  color: white;
  border-radius: 18px;
  padding: 10px 15px;
  position: relative;
  display: inline-block;
  max-width: 220px;
  word-wrap: break-word;
}

.message-content p {
  margin: 0;
  font-size: 14px;
}

.message-time {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  text-align: right;
  margin-top: 5px;
}
</style>
