<template>
  <div>
    <b-card class="custom-card">
      <div style="display: flex; justify-content:space-between">
        <div>
          <b-card-text
            style="text-transform: capitalize; color:#021526;font-weight:500 "><span>{{ client.businessType[0] }}</span></b-card-text>
        </div>
        <div class="custom-card-header d-flex">
          <b-button v-if="this.isNewUser === 'false' && this.isExternalUser === 'false'"
            :disabled="client.role != 'ADMIN'" @click="accessControl" variant="flat-success"
            class="btn-icon rounded-circle custom-icon">
            <feather-icon size="18" icon="UserPlusIcon"></feather-icon>
          </b-button>
          <!-- Only admin have permission to access this dropdown menu. -->
          <b-nav-item-dropdown :disabled="client.role != 'ADMIN'" right toggle-class="d-flex align-items-center">
            <template #button-content>
              <feather-icon size="18" class="more-icon" icon="MoreVerticalIcon"></feather-icon>
            </template>

            <b-dropdown-item @click="editClient" link-class="d-flex align-items-center">
              <feather-icon size="16" icon="Edit3Icon" class="mr-50" />
              <span>Edit Name</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="client.integrations.googleAds && client.googleAdsCustomerID || client.integrations.amazon && client.amazon.profileIDs[0] || client.integrations.facebook && client.facebook.adAccountIDs[0]"
              @click="sendSchedule(client._id)" link-class="d-flex align-items-center">
              <feather-icon size="16" icon="SendIcon" class="mr-50" />
              <span>Schedule Trigger</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteClient" link-class="d-flex align-items-center">
              <feather-icon size="16" icon="Trash2Icon" class="mr-50 text-danger" />
              <span class="text-danger">Delete Client</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </div>
      </div>
      <b-card-text>
        <div class="mt-1 text-center"
          v-if="client.integrations.googleAds && client.googleAdsCustomerID || client.integrations.amazon && client.amazon.profileIDs[0] || client.integrations.facebook && client.facebook.adAccountIDs[0] && client.integrations.salesforce">
          <b-avatar :to="`/channels-dashboard/${client.slug}`" size="64" :text="avatarText(client.name)"
            variant="light-primary" />
          <b-link :to="`/channels-dashboard/${client.slug}`">
            <h3 class="mt-1">{{ client.name }}</h3>
          </b-link>
        </div>
        <div class="mt-1 text-center" v-else-if="client.integrations.facebook && client.facebook.adAccountIDs[0]">
          <b-avatar :to="`/channels-dashboard/${client.slug}`" size="64" :text="avatarText(client.name)"
            variant="light-primary" />
          <b-link :to="`/channels-dashboard/${client.slug}`">
            <h3 class="mt-1">{{ client.name }}</h3>
          </b-link>
        </div>
        <div class="mt-1 text-center" v-else-if="client.integrations.amazon && client.amazon.profileIDs[0]">
          <b-avatar :to="`/channels-dashboard/${client.slug}`" size="64" :text="avatarText(client.name)"
            variant="light-primary" />
          <b-link :to="`/channels-dashboard/${client.slug}`">
            <h3 class="mt-1">{{ client.name }}</h3>
          </b-link>
        </div>
        <div class="mt-1 text-center" v-else>
          <div v-if="(!client.integrations.googleAds ||
            !client.integrations.facebook) &&
            this.isNewUser == 'false' && this.isExternalUser == 'true' && !client.stepper.data_synced
          ">
            <b-avatar :to="`/configuration-settings`" size="64" :text="avatarText(client.name)"
              variant="light-primary" />
            <b-link :to="`/configuration-settings`">
              <h3 class="mt-1">{{ client.name }}</h3>
            </b-link>
          </div>
          <div v-else>
            <b-avatar :to="`/client/${client.slug}`" size="64" :text="avatarText(client.name)"
              variant="light-primary" />
            <b-link :to="`/client/${client.slug}`">
              <h3 class="mt-1">{{ client.name }}</h3>
            </b-link>
          </div>

        </div>
      </b-card-text>

      <b-card-text class="connected-tools mt-2 mb-2">
        <div class="text-center" v-if="client.integrations.googleAds ||
          client.integrations.google ||
          client.integrations.facebook ||
          client.integrations.gmb ||
          client.integrations.amazon || client.integrations.salesforce || client.integrations.dv360
        ">
          <p class="mb-0">Tools Connected</p>
        </div>
        <div class="tools mt-1">
          <b-col cols="10" class="mx-auto">
            <div v-if="!client.integrations.googleAds &&
              !client.integrations.google &&
              !client.integrations.facebook &&
              !client.integrations.gmb && !client.integrations.amazon && !client.integrations.salesforce && !client.integrations.dv360 && this.isNewUser == 'false' && this.isExternalUser == 'false'
            " class="text-center">
              <b-button size="sm" variant="primary" :to="`/client/${client.slug}`">Connect Tools <feather-icon
                  icon="ArrowRightIcon"></feather-icon></b-button>
            </div>
            <div v-if="(!client.integrations.googleAds ||
              !client.integrations.facebook) &&
              this.isNewUser == 'false' && this.isExternalUser == 'true' && !client.stepper.data_synced
            " class="text-center">
              <b-button size="sm" variant="primary" :to="`/configuration-settings`">Continue<feather-icon
                  icon="ArrowRightIcon"></feather-icon></b-button>
            </div>
            <b-row v-else>
              <div style="display:flex; justify-content:center; height:auto; width:100%; left:auto; right:auto">
                <b-col v-if="client.integrations.google && client.googleViewID" cols="3">
                  <b-link :to="`/client/${client.slug}/dashboard/google-analytics`">
                    <b-img class="img-size" src="@/assets/images/portrait/small/analytics-avatar.png"
                      alt="google-analytics"></b-img>
                  </b-link>
                </b-col>
                <b-col v-if="client.integrations.googleAds && client.googleAdsCustomerID" cols="3">
                  <b-link :to="`/client/${client.slug}/dashboard/google-ads`">
                    <b-img class="img-size" src="@/assets/images/portrait/small/ads-avatar.png"
                      alt="google-ads"></b-img>
                  </b-link>
                </b-col>
                <b-col v-if="client.integrations.gmb && client.gmb.accountID.length" cols="3">
                  <b-link :to="`/gmb/${client.slug}`">
                    <b-img class="img-size" src="@/assets/images/logo/gmb.jpeg" alt="gmb"></b-img>
                  </b-link>
                </b-col>
                <b-col v-if="client.integrations.facebook" cols="3">
                  <b-link :to="`/client/${client.slug}/dashboard/meta-ads`">
                    <b-img class="img-size" src="@/assets/images/logo/meta.png" alt="meta-ads"></b-img>
                  </b-link>
                </b-col>
                <b-col v-if="client.integrations.dv360 && client.dv360.partnerID" cols="3">
                  <b-link :to="`/client/${client.slug}/dashboard/dv360`">
                    <b-img class="img-size" src="@/assets/images/logo/dv360-logo.svg"
                      style="height: 40px; margin-left:-10px" alt="dv360"></b-img>
                  </b-link>
                </b-col>

                <b-col v-if="client.integrations.amazon" cols="3">
                  <b-link :to="`/client/${client.slug}/dashboard/amazon-ads`">
                    <b-img class="img-size" src="@/assets/images/logo/amazon-logo.webp" alt="amazon-ads"></b-img>
                  </b-link>
                </b-col>
                <b-col v-if="client.integrations.leadSquared" cols="3">
                  <b-link :to="`/client/${client.slug}/dashboard/leadsquared`">
                    <b-img style="height: 60px; width:60px; margin-top:-10px;margin-left:-10px"
                      src="@/assets/images/logo/leadSquare.png" alt="amazon-ads"></b-img>
                  </b-link>
                </b-col>
                <b-col v-if="client.integrations.salesforce" cols="3">
                  <b-link :to="`/client/${client.slug}/dashboard/salesforce`">
                    <b-img style="height: 50px; width:50px;"
                      src="@/assets/images/logo/salesforce.svg" alt="salef"></b-img>
                  </b-link>
                </b-col>
              </div>
            </b-row>
          </b-col>
        </div>
      </b-card-text>
      <div v-if="this.client && this.client.integrations && this.client.integrations.googleAds || this.client && this.client.integrations && this.client.integrations.facebook" style="margin-bottom: -15px; margin-left:-10px">
        <span style="font-size: 9px;"><strong>Last Updated: </strong>{{this.formatDateAndTimeForSync(this.client.updatedAt)}}</span>
      </div>
    </b-card>
    <transition name="modal">
      <div class="modal-mask" v-if="openScheduleModal">
        <TriggerModalAll @closeScheduleClientPopUp="closeScheduleClientPopUp" :clientID="client._id"
          :scheduleModal="this.openScheduleModal" :isTriggerModal="isTriggerModal" />
      </div>
    </transition>
  </div>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TriggerModalAll from "@/components/client/TriggerModalAll.vue"
import client from "@/router/client";
import vSelect from "vue-select";
import Cookies from 'js-cookie';
import {
  BCard,
  BCardText,
  BLink,
  BImg,
  BRow,
  BCol,
  BButton,
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
  BModal,
  BFormSelect,
  BSpinner
} from "bootstrap-vue";

export default {
  data() {
    return {
      openScheduleModal: false,
      isNewUser: null,
      isExternalUser: null
    }
  },
  props: {
    lastTimeDataFetchedTime:{
      type: String,
    },
    client: {
      type: Object,
      default: () => {
        return {
          openScheduleModal: false,
          name: "",
          slug: "",
          integrations: [],
          googleViewID: "",
          googleAdsCustomerID: "",
          sector: "",
          role: "",
          triggerData: [],
          showTriggerModal: false,
          isTriggerModal: false,
          isDataSync: false,
        };
      },
    },
  },
  components: {
    BAvatar,
    BCard,
    BCardText,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BNavItemDropdown,
    BDropdownItem,
    TriggerModalAll,
    ToastificationContent,
    BModal,
    BFormSelect,
    vSelect,
    BSpinner
  },
  methods: {
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    formatDateAndTimeForSync(isoString) {
  const date = new Date(isoString);

  // Extract individual components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Format as 'YYYY-MM-DD HH:mm:ss'
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    sendSchedule() {
      this.clientID = this.client._id;
      this.openScheduleModal = true;
      this.isTriggerModal = false
    },
    closeScheduleClientPopUp() {
      this.openScheduleModal = false;
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    editClient() {
      this.$emit("editClient", {
        clientID: this.client._id,
        name: this.client.name,
        sector: this.client.sector,
        selectedBusinessType: this.client.businessType
      });
    },
    deleteClient() {
      this.$emit("deleteClient", {
        name: this.client.name,
        clientID: this.client._id,
      });
    },
    accessControl() {
      this.$emit("accessControl", {
        name: this.client.name,
        clientID: this.client._id,
      });
    },
  },
  mounted() {
    this.isNewUser = Cookies.get(useJwt.jwtConfig.userNewUser);
    this.isExternalUser = Cookies.get(useJwt.jwtConfig.userExternalUser);
  }
};
</script>

<style>
.disable-scroll {
  overflow: hidden;
}

.animated-message-text-business {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 10px;
}

.animated-message-text-business span {
  position: absolute;
  width: 90%;
  font-size: 12px;
  color: transparent;
  background-image: linear-gradient(to right, #553c9a, #ee4b2b, #00c2cb, #ff7f50, #553c9a);
  -webkit-background-clip: text;
  background-clip: text;
  background-size: 200%;
  background-position: -200%;
  animation: animated-gradient 2s infinite alternate-reverse;
}

@keyframes animated-gradient {
  to {
    background-position: 200%;
  }
}

.img-size {
  width: 45px !important;
}
</style>