let aiProjectMap = {
  'ai': {
    'leadgen': {

      'ad': {
        'name': 'Ad Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'cvr': 'CVR',
        'conversions': 'Leads',
        'cpc': 'CPL',
      },
      'adset': {
        'name': 'Adset Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'cvr': 'CVR',
        'conversions': 'Leads',
        'cpc': 'CPL',
      },
      'campaign': {
        'name': 'Campaign Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'cvr': 'CVR',
        'conversions': 'Leads',
        'cpc': 'CPL',
      },

      'adgroup': {
        'name': 'AdGroup Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'conversions': 'Leads',
        'cpc': 'CPL',
      },

      'insertionOrder': {
        'name': 'Insertion Order Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpv': 'CPV',
        'ctr': 'CTR',
        'frequency': 'Frequency',

      },
      'lineItem': {
        'name': 'lineItem Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpv': 'CPV',
        'ctr': 'CTR',
        'frequency': 'Frequency',

      },
    },
    'leadform': {
      'ad': {
        'name': 'Ad Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'cvr': 'CVR',
        'conversions': 'Leads',
        'cpc': 'CPL',
      },
      'adset': {
        'name': 'Adset Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'cvr': 'CVR',
        'conversions': 'Leads',
        'cpc': 'CPL',
      },
      'campaign': {
        'name': 'Campaign Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'cvr': 'CVR',
        'conversions': 'Leads',
        'cpc': 'CPL',
      },
      'adgroup': {
        'name': 'AdGroup Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'conversions': 'Leads',
        'cpc': 'CPL',
      },
      'insertionOrder': {
        'name': 'Insertion Order Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpv': 'CPV',
        'ctr': 'CTR',
        'frequency': 'Frequency',

      },
      'lineItem': {
        'name': 'lineItem Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpv': 'CPV',
        'ctr': 'CTR',
        'frequency': 'Frequency',
      },
    },
    'ecommerce': {

      'ad': {
        'name': 'Ad Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'cvr': 'CVR',
        'purchases': 'Purchases',
        'roas': 'ROAS'
      },
      'adset': {
        'name': 'Adset Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'cvr': 'CVR',
        'purchases': 'Purchases',
        'roas': 'ROAS'
      },
      'campaign': {
        'name': 'Campaign Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'cvr': 'CVR',
        'purchases': 'Purchases',
        'roas': 'ROAS'
      },

      'adGroup': {
        'name': 'AdGroup Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'purchases': 'Purchases',
        'roas': 'ROAS'
      },

      'insertionOrder': {
        'name': 'Insertion Order Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpv': 'CPV',
        'ctr': 'CTR',
        'frequency': 'Frequency',

      },
      'lineItem': {
        'name': 'lineItem Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpv': 'CPV',
        'ctr': 'CTR',
        'frequency': 'Frequency',

      },

    },
    'default': {

      'ad': {
        'adName': 'Ad Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'cvr': 'CVR',
        'conversions': 'Leads',
        'cpc': 'CPL',
      },
      'adset': {
        'adsetName': 'Adset Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'cvr': 'CVR',
        'conversions': 'Leads',
        'cpc': 'CPL',
      },
      'campaign': {
        'campaignName': 'Campaign Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'cvr': 'CVR',
        'conversions': 'Leads',
        'cpc': 'CPL',
      },

      'adgroup': {
        'adGroupName': 'AdGroup Name',
        'costMicros': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpcl': 'CPC',
        'ctr': 'CTR',
        'conversions': 'Leads',
        'cpc': 'CPL',
      },

      'insertionOrder': {
        'insertionOrderName': 'Insertion Order Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpv': 'CPV',
        'ctr': 'CTR',
        'frequency': 'Frequency',

      },
      'lineItem': {
        'lineItemName': 'lineItem Name',
        'spends': 'Spends',
        'impressions': 'Impressions',
        'clicks': 'Clicks',
        'cpm': 'CPM',
        'cpv': 'CPV',
        'ctr': 'CTR',
        'frequency': 'Frequency',

      },
    },
  },
}

export default aiProjectMap

