<template>
  <div class="support-button draggable-btn"
  :style="{ top: position.y + 'px', left: position.x + 'px' }"
  @mousedown="startDrag">
    <div class="bot-icon">
        <img src="https://cdn-icons-png.flaticon.com/512/4712/4712035.png" alt="Bot Icon">
    </div>
    <div class="text-container">
        <div class="bot-name">D2Scale-AI</div>
        <div class="status">Online</div>
    </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      position: {
        x: window.innerWidth - 200,
        y: window.innerHeight - 80 
      },
      dragging: false,
      offset: { x: 0, y: 0 },
    };
  },
  methods: {
    startDrag(event) {
      this.dragging = true;
      this.offset.x = event.clientX - this.position.x;
      this.offset.y = event.clientY - this.position.y;
      document.addEventListener("mousemove", this.drag);
      document.addEventListener("mouseup", this.stopDrag);
    },
    drag(event) {
      if (this.dragging) {
        this.position.x = event.clientX - this.offset.x;
        this.position.y = event.clientY - this.offset.y;
      }
    },
    stopDrag() {
      this.dragging = false;
      document.removeEventListener("mousemove", this.drag);
      document.removeEventListener("mouseup", this.stopDrag);
    },
  },
};
</script>

<style scoped lang="scss">
.draggable-btn {
  position: fixed; /* Ensure it stays in the same place even when scrolling */
  cursor: grab;
  user-select: none;
}

.draggable-btn:active {
  cursor: grabbing;
}
.support-button {
  display: flex;
  align-items: center;
  background: #f0f2f5;
  border-radius: 50px;
  border: 4px solid #200E6B;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  width: fit-content;

.bot-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #2D8CFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
}

.bot-icon::after {
  content: '';
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 10px;
  height: 10px;
  background: #28C940;
  border-radius: 50%;
  border: 2px solid white;
}

.bot-icon img {
  width: 24px;
  height: 24px;
}

.text-container {
  display: flex;
  flex-direction: column;
}

.bot-name {
  font-size: 14px;
  font-weight: bold;
  color: #200E6B;
}

.status {
  font-size: 14px;
  color: #9B9B9B;
}
}

</style>
